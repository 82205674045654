$HAGILE_PRIMARY_COLOR: #cadb14;
$HAGILE_SECONDARY_COLOR: #5e7148;
$HAGILE_SECONDARY_COLOR_LIGHT: #8ca074;
$HAGILE_PRIMARY_TEXT_COLOR: #fafafa;
$HAGILE_SECONDARY_TEXT_COLOR: $HAGILE_SECONDARY_COLOR;
$HAGILE_THIRD_TEXT_COLOR: #2b2b2b;

$HAGILE_TEXT_COLOR: #707070;
$HAGILE_BACKGROUND_COLOR: #fff;

$HAGILE_SECONDARY_BACKGROUND_COLOR: #fafafa;

$theme-colors: (
  "primary": $HAGILE_PRIMARY_COLOR,
  "secondary": $HAGILE_SECONDARY_COLOR,
);

// Removes outlined button outline
// https://github.com/twbs/bootstrap/blob/main/scss/_buttons.scss
$btn-border-width: 0;

$btn-border-radius: 0;
$btn-border-radius-lg: 0;
$btn-border-radius-sm: 0;

$btn-padding-x: 1.5rem;
$btn-padding-y: 0.4rem;
$btn-font-weight: 600;

// Modal
$modal-header-border-width: 0;
$modal-footer-border-width: 0;
$modal-inner-padding: 0rem 4rem 2rem;

@import "~bootstrap/scss/bootstrap";
