@import "./theme.scss";
@import "~react-datetime/css/react-datetime.css";
/* Basic create-react-app style */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* https://gist.github.com/kkga/db9d20b812fc158cc272be5f96f4e58f */
/* flex-reset.css */

* {
  box-sizing: border-box;
}
html,
body {
  min-height: 100vh;
  margin: 0;
  color: $HAGILE_TEXT_COLOR;
}

a {
  text-decoration: none !important;
}

h2 {
  color: $HAGILE_SECONDARY_TEXT_COLOR;
  font-size: 2rem;
  font-weight: 400;
}

h3 {
  font-weight: 300;
  font-size: 1.5rem;
  color: $HAGILE_SECONDARY_TEXT_COLOR;
}

h4 {
  font-weight: 500;
  font-size: 1.35rem;
  color: $HAGILE_SECONDARY_TEXT_COLOR;
}

h4.light {
  font-weight: 300;
}

.main--container {
  padding-top: 1rem;
  padding-bottom: 2rem;
  // at least full page height, minus the header and footer heights
  min-height: calc(100vh - 3.5rem - 20rem);
}

.main--sidebar {
  background-color: $HAGILE_SECONDARY_BACKGROUND_COLOR;
}

.bg-light {
  background-color: transparent !important;
}

.home-form-container {
  background-color: #fff;
  border-radius: 0.25rem;
  padding: 1.5rem;
  margin-top: 3rem;
  margin-bottom: 4rem;
}

.route-stage-form--container {
  .route-stage-form--wrapper {
    width: 100%;
  }

  .route-stage-form--map {
    width: 100%;
    height: 100vw;
    max-height: 640px;
  }

  @include media-breakpoint-up(lg) {
    & {
      position: relative;
    }

    .route-stage-form--wrapper {
      position: absolute;
      top: 1.2rem;
      left: 1.2rem;
      max-width: 500px;
      padding: 1.2rem;
      z-index: 1000;
      background-color: #fff;
      border-radius: 0.25rem;
    }

    .route-stage-form--map {
      height: 640px;
    }
  }
}

.tabbed-radio-input--container {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .tabbed-radio-input--item {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 12rem;
    padding: 0.4rem 2rem;
    margin-bottom: 0;

    &:hover {
      color: $HAGILE_SECONDARY_COLOR_LIGHT;
    }

    &.checked {
      color: $HAGILE_PRIMARY_COLOR;
    }

    &.filled {
      &.checked {
        background-color: $HAGILE_PRIMARY_COLOR;
        color: $HAGILE_PRIMARY_TEXT_COLOR;
      }
    }

    &.disabled {
      color: lightgray;
    }

    .tabbed-radio-input--item-label {
      text-align: center;
      font-size: 1.15rem;
    }
  }

  .tabbed-radio-input--separator {
    width: 1px;
    margin: 0.4rem 0;
    background-color: #666;
  }
}

.radio-input--container {
  border: 1px solid;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &.checked {
    border-color: $HAGILE_PRIMARY_COLOR;
    background-color: $HAGILE_PRIMARY_COLOR;
    color: #fff;
  }
}

.btn-primary {
  color: #fff;
}

.icon-btt {
  padding: 0.25rem;
  border: 0;
  background-color: transparent;
}

.back-btt {
  padding: 0.3rem;
  margin-right: 0.4rem;
  border: 0;
  background-color: transparent;
  color: #000;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent !important;
  }

  svg {
    width: 36px;
    height: 36px;
  }
}

.submitFormBtt {
  width: 100%;
  border-radius: 0;
  max-width: 250px;
  font-weight: 600;
  font-size: 1.35rem;
  padding-top: 0.7rem;
  padding-bottom: 0.6rem;

  svg {
    width: 30px;
    height: 30px;
    margin-left: 4rem;
    margin-bottom: 0.2rem;
  }
}
.submitFormBtt.compact {
  width: auto;

  svg {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

.secondary-link {
  color: $HAGILE_SECONDARY_COLOR;
}

.login-guard-stage-form--container {
  .secondary-link {
    display: flex;
    flex-direction: column;
    .icon-link {
      flex-direction: row;
    }
  }
}

.autocomplete--container {
  position: relative;

  .autocomplete--list {
    display: none;
    position: absolute;
    z-index: 100;
    width: 100%;
    background: white;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow: hidden;

    &.show {
      display: block;
    }
  }

  .autocomplete--list-item {
    padding: 0.6rem 1.1rem;
    cursor: pointer;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;

    &:hover {
      background-color: $HAGILE_PRIMARY_COLOR;
    }

    // &:not(:last-child) {
    //   border-bottom: 1px solid #eee;
    // }
  }
}

.form-container-sm {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.form--error-label {
  font-size: 0.8rem;
  color: red;
}

.home--primary-links-wrapper {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
  text-align: center;

  svg {
    height: 2.5rem;
    width: auto;
  }
}

.header--container {
  display: flex;
  align-items: center;
  height: 3.5rem;
}

.container {
  max-width: 100%;
}

.footer--container {
  min-height: 20rem;
  background-color: $HAGILE_SECONDARY_COLOR_LIGHT;

  li {
    list-style: none;
    margin-bottom: 0.25rem;
  }

  a,
  a:hover,
  a:visited,
  a:focus {
    text-decoration: none;
    color: $HAGILE_THIRD_TEXT_COLOR;
  }
}

.stage-status--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-bottom: 2rem;

  .stage-status--header {
    display: flex;
    align-items: center;
    cursor: pointer;

    h3 {
      display: none;
      margin-left: 1rem;
      margin-bottom: 0;
    }

    &.active-stage {
      color: $HAGILE_PRIMARY_COLOR;
      h3 {
        font-weight: 500;
        color: $HAGILE_PRIMARY_COLOR;
      }
    }
    &.disabled {
      opacity: 0.5;
      cursor: auto;
    }
  }

  .stage-status--list {
    // Hide on mobile, too cluttering
    display: none;

    .stage-status--item {
      .stage-status--item-label {
        max-width: 35%;
        width: 8em;
        text-align: right;
        vertical-align: top;
        padding-left: 0.5rem; // helps center
        padding-right: 0.6rem;
      }
    }
  }

  .stage-status--section-separator {
    color: lightgray;
    margin: 0 -0.35rem 0;

    &:last-child {
      display: none;
    }
  }

  @include media-breakpoint-up(md) {
    & {
      flex-direction: column;
      align-items: initial;
    }

    .stage-status--section-separator {
      display: none;
    }

    .stage-status--section {
      margin-bottom: 2rem;
    }

    .stage-status--list {
      display: table;
    }

    .stage-status--header h3 {
      display: block;
    }
  }
}

.stage-form {
  .stage-form--title {
    // margin to align with stage-status title height
    margin-top: 0.6rem;
  }
}

.main-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3em;

  @extend .sticky-top;
  background-color: white;
  padding: 0.5rem 0;

  h3,
  h4 {
    // Removes default theme margin to better align
    margin-bottom: 0;
  }
}

.pagination-controls {
  position: sticky;
  left: 0;
  bottom: 0;

  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding: 0.5rem 0;
  background-color: $HAGILE_BACKGROUND_COLOR;
}

.toggle-btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid $HAGILE_SECONDARY_COLOR;
  color: $HAGILE_SECONDARY_COLOR;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-left: 0.5rem;
  margin-right: 0.5rem;

  &.active {
    background-color: $HAGILE_PRIMARY_COLOR;
    color: #fff;
    border-color: $HAGILE_PRIMARY_COLOR;
  }

  &.block {
    width: 100%;
  }
}

.vertical-btt-group {
  display: flex;
  flex-direction: column;

  .btn {
    border-radius: 0.25rem !important;
    margin-bottom: 1em;
  }

  .btn-primary {
    background-color: transparent !important;
    border: 1px solid $HAGILE_SECONDARY_COLOR;
    color: $HAGILE_SECONDARY_COLOR;

    &.active {
      background-color: $HAGILE_PRIMARY_COLOR !important;
      color: #fff !important;
      border-color: $HAGILE_PRIMARY_COLOR !important;
    }
  }
}

.MuiPickersToolbar-toolbar,
.MuiPickersDay-daySelected,
.MuiPickersClock-pin,
.MuiPickersClockPointer-pointer,
.MuiPickersClockPointer-noPoint {
  background-color: $HAGILE_PRIMARY_COLOR !important;
}

.MuiPickersClockPointer-thumb {
  border-color: $HAGILE_PRIMARY_COLOR !important;
}

.MuiPickersDay-current {
  color: $HAGILE_SECONDARY_COLOR !important;
}

.MuiFormControl-root {
  display: block !important;
  width: 100% !important;
}

.MuiInputBase-input {
  display: block !important;
  width: 100% !important;
  height: calc(1rem + 2px) !important;
  padding: 0.525rem 0.75rem !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #495057 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.MuiInput-underline:before,
.MuiInput-underline:after {
  content: "" !important;
  border-bottom: 0px !important;
}

// Modal customization

.modal-title {
  @extend h4;
  @extend .text-center;
  @extend .w-100;
}

// Atomic utilites

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.center-vertically {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.is-clickable {
  cursor: pointer;
}

.confirmation-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 40px;
}

.call-operator-btt {
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 64px;
  height: 64px;
  background-color: #cadb14;
  border-radius: 50%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;

  img {
    width: 42px;
    height: 42px;
  }
}
